import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid as MuiGrid,
  Accordion,
  AccordionDetails,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { spacing } from "@material-ui/system";
import { firstOfYear, lastOfYear, lineColors } from "../../../utils";
import DatePicker from "../../../components/pickers/DatePicker";
import Panel from "../../../components/panels/Panel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ExportDataButton from "../../../components/graphs/ExportDataButton";
import SaveRefButton from "../../../components/graphs/SaveRefButton";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";
import TimeseriesLineChartDataViz from "../../../components/graphs/TimeseriesLineChartDataViz";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

const OuterContainer = styled(Box)`
  margin-left: 49px;
  bottom: 48px;
  z-index: 3;
  position: absolute;
  max-height: 100%;
  width: calc(100% - 49px - 49px);
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Viz = styled.div`
  height: ${({ height }) => height};
  max-width: 100%;
`;

const TimeseriesWrapper = styled.div`
  height: calc(100% - 78px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: calc(${({ height }) => height} - 146px);
  width: 100%;
`;

const CircleMarker = styled.div`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled.div`
  // position: absolute;
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const DataViz = ({
  open = false,
  dataVizWellNumber,
  dataVizGraphType,
  onClose,
}) => {
  const divSaveRef = useRef(null);
  const graphSaveRef = useRef(null);

  //date filter defaults
  const defaultFilterValues = {
    // startDate: lastOfJanuary,
    startDate: null,
    endDate: new Date(),
  };

  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const [currentSelectedTimeseriesData, setCurrentSelectedTimeseriesData] =
    useState(null);
  useEffect(() => {
    if (dataVizWellNumber && dataVizGraphType) {
      async function send() {
        try {
          const endpoint = {
            has_temperature_graph: "graph-temperature-data",
            has_flow_graph: "graph-flow-data",
          };

          const { data: results } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint[dataVizGraphType]}/${dataVizWellNumber}`
          );

          if (results.length) {
            setCurrentSelectedTimeseriesData(results);
          } else {
            setCurrentSelectedTimeseriesData(null);
          }
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
        }
      }
      send();
    }
  }, [dataVizWellNumber, dataVizGraphType]); // eslint-disable-line

  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState({});
  useEffect(() => {
    if (currentSelectedTimeseriesData?.length) {
      //mutate data for chartJS to use
      let graphData;
      if (dataVizGraphType === "has_temperature_graph") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.collect_date)
          ),
          datasets: [
            {
              label: "Average Min Temp",
              units: currentSelectedTimeseriesData[0].units,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.blue,
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.min_daily_temp
              ),
              popupInfo: currentSelectedTimeseriesData.map(
                (item) => item.source
              ),
              pointRadius: 0,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
            {
              label: "Average Daily Temp",
              units: currentSelectedTimeseriesData[0].units,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.green,
              borderColor: lineColors.green,
              data: currentSelectedTimeseriesData.map(
                (item) => item.avg_daily_temp
              ),
              pointRadius: 0,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
            {
              label: "Average Max Temp",
              units: currentSelectedTimeseriesData[0].units,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.red,
              borderColor: lineColors.red,
              data: currentSelectedTimeseriesData.map(
                (item) => item.max_daily_temp
              ),
              pointRadius: 0,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
          ],
        };
      } else if (dataVizGraphType === "has_flow_graph") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.collect_date)
          ),
          datasets: [
            {
              label: "Average Daily Flow",
              units: currentSelectedTimeseriesData[0].units,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.blue,
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.avg_daily_flow
              ),
              popupInfo: currentSelectedTimeseriesData.map(
                (item) => item.source
              ),
              pointRadius: 0,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
          ],
        };
      }
      setFilteredMutatedGraphData(graphData);
    } else {
      setFilteredMutatedGraphData(null);
    }
  }, [currentSelectedTimeseriesData, dataVizGraphType]);

  const formatTableTitle = (graphType) => {
    if (["has_flow_graph", "has_temperature_graph"].includes(graphType)) {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            <strong>
              {currentSelectedTimeseriesData[0].location_name ?? "NA"}
            </strong>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>{currentSelectedTimeseriesData[0].location_id ?? "NA"}</Box>
          </Typography>
        </>
      );
    } else {
      return null;
    }
  };

  const [dataVizHeight, setDataVizHeight] = useState({
    viz: "460px",
    timeSeries: "500px",
  });

  const handleExpand = () => {
    let newState = { ...dataVizHeight };
    if (newState.viz === "460px" && newState.timeSeries === "500px") {
      newState.viz = "70vh";
      newState.timeSeries = "860px";
    } else {
      newState.viz = "460px";
      newState.timeSeries = "500px";
    }
    setDataVizHeight(newState);
  };

  return (
    <OuterContainer
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      open={open}
    >
      <Viz height={dataVizHeight.viz}>
        <CloseContainer>
          <ExpandButton
            handleExpand={handleExpand}
            expanded={dataVizHeight.viz !== "460px"}
          />
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={onClose}
              style={{ marginLeft: "4px" }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </CloseContainer>
        {filteredMutatedGraphData && currentSelectedTimeseriesData && (
          <Panel overflowY="scroll" overflowX="hidden">
            <TimeseriesContainer
              height={dataVizHeight.timeSeries}
              ref={divSaveRef}
            >
              <Box ml={4} pt={2} pb={2} display="flex" alignItems="center">
                <CircleMarker>
                  <Room />
                </CircleMarker>
                <Box flexDirection="column" display="flex">
                  {formatTableTitle(dataVizGraphType)}
                </Box>

                <Box
                  style={{ marginLeft: "auto" }}
                  data-html2canvas-ignore={"true"}
                  display="flex"
                >
                  <ExportDataButton
                    title="well_ndx"
                    data={currentSelectedTimeseriesData}
                    filterValues={filterValues}
                  />
                  <SaveRefButton
                    data-html2canvas-ignore
                    ref={divSaveRef}
                    title={dataVizWellNumber}
                  />
                </Box>
              </Box>

              <TimeseriesWrapper>
                <TimeseriesLineChartDataViz
                  data={filteredMutatedGraphData}
                  // error={error}
                  // isLoading={isLoading}
                  yLLabel={
                    dataVizGraphType === "has_temperature_graph"
                      ? `Water Temperature, ${currentSelectedTimeseriesData[0].units}`
                      : dataVizGraphType === "has_flow_graph"
                      ? `Average Daily Flow, ${currentSelectedTimeseriesData[0].units}`
                      : null
                  }
                  xLabelFormat="MMM DD YYYY"
                  reverseLegend={true}
                  enableLegendClick={true}
                  ref={graphSaveRef}
                  filterValues={filterValues}
                  type="line"
                  displayLegend={true}
                  xLabelUnit="day"
                  maxTicksX={12}
                  maxTicksYL={6}
                  maxTicksYR={5}
                  align="start"
                />
              </TimeseriesWrapper>
            </TimeseriesContainer>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map"
                id="map"
              >
                <Typography variant="h4">Filters</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} sm={6} md={5}>
                    <DatePicker
                      label="Start Date"
                      name="startDate"
                      selectedDate={filterValues.startDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <DatePicker
                      label="End Date"
                      name="endDate"
                      selectedDate={filterValues.endDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={12}>
                        <Tooltip title="Period of Record">
                          <Button
                            size="small"
                            style={{ width: "100%" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              changeFilterValues("startDate", null);
                              changeFilterValues("endDate", new Date());
                            }}
                          >
                            Record
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} sm={6} md={12}>
                        <Tooltip title="Current Year" placement="bottom">
                          <Button
                            size="small"
                            style={{ width: "100%" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              changeFilterValues("startDate", firstOfYear);
                              changeFilterValues("endDate", lastOfYear);
                            }}
                          >
                            Year
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Panel>
        )}
      </Viz>
    </OuterContainer>
  );
};

export default DataViz;
