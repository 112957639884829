/* eslint-disable import/first */
import React from "react";
import { CRUD_MODELS, ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import LocalDrink from "@material-ui/icons/LocalDrink";

import {
  Activity,
  Database,
  Home,
  Monitor,
  Users,
  Map as MapIcon,
  FileText,
  HelpCircle,
} from "react-feather";

import Landing from "../pages/presentation/Landing";
import * as inflector from "inflected";
import { CrudProvider } from "../CrudProvider";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import Default from "../pages/dashboards/Default";
import AuthGuard from "../components/AuthGuard";
import TimeSeriesComparison from "../pages/dataAccess/timeSeries/TimeSeriesComparison";
import SitesSummaryTable from "../pages/dataAccess/Reports/SitesSummaryTable";
import AdminResources from "../pages/dataAccess/AdminResources";
import ResopsView01TimeSeries from "../pages/dataAccess/timeSeries/ResopsView01TimeSeries";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));
const CrudViewPage = async(() => import("../components/crud/CrudViewPage"));

const getSidebarMenu = (list) => {
  return list.map((item) => {
    const slug = inflector.dasherize(inflector.underscore(item.name));
    return {
      id: item.sidebarName ?? inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      icon: item.icon || <Database />,
      component: CrudIndexPage,
      config: require(`../pages/models/${item.name}Config`),
      provider: CrudProvider,
      children: item.children,
      header: item.header,
      guard: item.guard,
      visibilityFilter: item.visibilityFilter,
    };
  });
};

const getCrudRoutes = (list) => {
  return list.map((item) => {
    const config = require(`../pages/models/${item.name}Config`);
    const slug = inflector.dasherize(inflector.underscore(item.name));

    return {
      id: inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      component: CrudIndexPage,
      provider: CrudProvider,
      config,
      crud: [
        {
          path: `/models/${slug}/:id`,
          name: `View ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
        {
          path: `/models/${slug}/add`,
          name: `Add ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
      ],
    };
  });
};

const crudSidebarMenu = [...getSidebarMenu(CRUD_MODELS)];
const modelCrudRoutes = [...getCrudRoutes(CRUD_MODELS)];

const publicMapRoutes = {
  header: "Data Access",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/data-access/map-explorer",
  name: "Map Explore",
  component: PublicMap,
  guard: AuthGuard,
};

const dataAccessRoutes = {
  id: "Time Series Comparisons",
  icon: <Activity />,
  path: "/data-access/time-series-comparisons",
  name: "Time Series Comparisons",
  component: TimeSeriesComparison,
  guard: AuthGuard,
};

const ResopsTimeSeriesRoutes = {
  id: "Reservoir Operations",
  icon: <LocalDrink />,
  children: [
    {
      path: "/data-access/resops-time-series-1",
      name: "Stagecoach Study Section 1",
      component: ResopsView01TimeSeries,
    },
    // {
    //   path: "/data-access/resops-time-series-2",
    //   name: "Study Section 2",
    //   component: ResopsView02TimeSeries,
    // },
  ],
  guard: AuthGuard,
};

const reportsRoutes = {
  id: "Site Summary Table",
  icon: <FileText />,
  path: "/data-access/site-summary-table",
  name: "Site Summary Table",
  component: SitesSummaryTable,
  guard: AuthGuard,
};

const resourcesRoutes = {
  id: "Admin Resources",
  icon: <HelpCircle />,
  path: "/data-access/admin-resources",
  name: "Admin Resources",
  component: AdminResources,
  guard: AuthGuard,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  guard: AuthGuard,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  header: "Dashboards",
  id: "Operations Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  dataAccessRoutes,
  ResopsTimeSeriesRoutes,
  reportsRoutes,
  resourcesRoutes,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  ...crudSidebarMenu,
  ...modelCrudRoutes,
  publicMapRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  mainRoutes,
  ...crudSidebarMenu,
  publicMapRoutes,
  dataAccessRoutes,
  ResopsTimeSeriesRoutes,
  reportsRoutes,
  resourcesRoutes,
];
