import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { DatePicker, Select } from "@lrewater/lre-react";
import { dateFormatter, extractDate, lineColors } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Button as MuiButton,
  Box,
  // Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import TimeseriesLineChartResops from "../../../components/graphs/TimeseriesLineChartResops";
import { add } from "date-fns";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 35px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const ResopsView01TimeSeries = () => {
  const saveRef = useRef(null);

  const Endpoints = [
    { label: "Daily", value: "resops-view01-final-daily" },
    { label: "Hourly", value: "resops-view01-final-hourly" },
  ];

  const [filterValues, setFilterValues] = useState({
    endpoint: "resops-view01-final-daily",
    startDate: extractDate(add(new Date(), { days: -60 })),
    endDate: extractDate(new Date()),
  });

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      newValues[name] = value;

      return newValues;
    });
  };

  const { data, error, isFetching, refetch } = useQuery(
    ["ResopsView01TimeSeries"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.startDate}/${filterValues.endDate}`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const tableColumns = [
    { title: "Name", field: "view_name" },
    {
      title: "Last Report",
      field: "collect_timestamp",
      render: (rowData) => {
        return dateFormatter(rowData.collect_timestamp, "MM/DD/YYYY, h:mm A");
      },
    },
    { title: "Units", field: "units" },
    { title: "Reservoir Level", field: "Reservoir Level" },
    { title: "P1B", field: "P1B" },
    { title: "P1B Threshold", field: "P1B Threshold" },
    { title: "P1B Action", field: "P1B Action" },
    { title: "P1C", field: "P1C" },
    { title: "P1C Threshold", field: "P1C Threshold" },
    { title: "P1C Action", field: "P1C Action" },
    { title: "P1D", field: "P1D" },
    { title: "P1D Threshold", field: "P1D Threshold" },
    { title: "P1D Action", field: "P1D Action" },
    { title: "P1E", field: "P1E" },
    { title: "P1E Threshold", field: "P1E Threshold" },
    { title: "P1E Action", field: "P1E Action" },
  ];

  const [annotatedLines, setAnnotatedLines] = useState({});
  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState({});
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data) {
      const graphData = {
        labels: data.map((item) => new Date(item.collect_timestamp)),
        datasets: [
          {
            label: "Reservoir Level",
            units: "Ft ab MSL",
            yAxisID: "yL",
            borderColor: lineColors.black,
            backgroundColor: lineColors.black + "4D",
            data: data.map((item) => item["Reservoir Level"]),
            fill: false,
            spanGaps: false,
            borderWidth: 3,
            pointStyle: "point",
            pointRadius: 0,
            pointHoverRadius: 4,
          },
          {
            label: "P1B",
            units: "Ft ab MSL",
            yAxisID: "yL",
            borderColor: lineColors.red,
            backgroundColor: lineColors.red + "4D",
            data: data.map((item) => item.P1B),
            fill: false,
            spanGaps: false,
            borderWidth: 3,
            pointStyle: "point",
            pointRadius: 0,
            pointHoverRadius: 4,
          },
          {
            label: "P1C",
            units: "Ft ab MSL",
            yAxisID: "yL",
            borderColor: lineColors.blue,
            backgroundColor: lineColors.blue + "4D",
            data: data.map((item) => item.P1C),
            fill: false,
            spanGaps: false,
            borderWidth: 3,
            pointStyle: "point",
            pointRadius: 0,
            pointHoverRadius: 4,
          },
          {
            label: "P1D",
            units: "Ft ab MSL",
            yAxisID: "yL",
            borderColor: lineColors.green,
            backgroundColor: lineColors.green + "4D",
            data: data.map((item) => item.P1D),
            fill: false,
            spanGaps: false,
            borderWidth: 3,
            pointStyle: "point",
            pointRadius: 0,
            pointHoverRadius: 4,
          },
          {
            label: "P1E",
            units: "Ft ab MSL",
            yAxisID: "yL",
            borderColor: lineColors.purple,
            backgroundColor: lineColors.purple + "4D",
            data: data.map((item) => item.P1E),
            fill: false,
            spanGaps: false,
            borderWidth: 3,
            pointStyle: "point",
            pointRadius: 0,
            pointHoverRadius: 4,
          },
        ],
      };
      const annotations = {
        annotations: {
          ...(data[0]["P1B Threshold"] !== null && {
            P1Bthreshold: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1B Threshold"],
              yMax: data[0]["P1B Threshold"],
              display: true,
              borderColor: lineColors.red,
              borderWidth: 3,
              borderDash: [15, 20],
              borderDashOffset: 0,
              label: {
                position: "start",
                yAdjust: 15,
                enabled: true,
                backgroundColor: lineColors.red,
                borderColor: lineColors.red,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () =>
                  "P1B Threshold: " + data[0]["P1B Threshold"] + " ft",
                rotation: "auto",
              },
            },
          }),
          ...(data[0]["P1B Action"] !== null && {
            P1Baction: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1B Action"],
              yMax: data[0]["P1B Action"],
              display: true,
              borderColor: lineColors.red,
              backgroundColor: lineColors.red,
              borderWidth: 4,
              borderCapStyle: "round",
              borderDash: [5, 10],
              borderDashOffset: 0,
              label: {
                position: "start",
                yAdjust: -15,
                enabled: true,
                backgroundColor: lineColors.red,
                borderColor: lineColors.red,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () => "P1B Action: " + data[0]["P1B Action"] + " ft",
                rotation: "auto",
              },
            },
          }),

          ...(data[0]["P1C Threshold"] !== null && {
            P1Cthreshold: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1C Threshold"],
              yMax: data[0]["P1C Threshold"],
              display: true,
              borderColor: lineColors.blue,
              borderWidth: 3,
              borderDash: [15, 20],
              borderDashOffset: 3,
              label: {
                position: "35%",
                yAdjust: 15,
                enabled: true,
                backgroundColor: lineColors.blue,
                borderColor: lineColors.blue,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () =>
                  "P1C Threshold: " + data[0]["P1C Threshold"] + " ft",
                rotation: "auto",
              },
            },
          }),
          ...(data[0]["P1C Action"] !== null && {
            P1Caction: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1C Action"],
              yMax: data[0]["P1C Action"],
              display: true,
              borderColor: lineColors.blue,
              backgroundColor: lineColors.blue,
              borderWidth: 4,
              borderCapStyle: "round",
              borderDash: [5, 10],
              borderDashOffset: 3,
              label: {
                position: "35%",
                yAdjust: -15,
                enabled: true,
                backgroundColor: lineColors.blue,
                borderColor: lineColors.blue,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () => "P1C Action: " + data[0]["P1C Action"] + " ft",
                rotation: "auto",
              },
            },
          }),

          ...(data[0]["P1D Threshold"] !== null && {
            P1Dthreshold: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1D Threshold"],
              yMax: data[0]["P1D Threshold"],
              display: true,
              borderColor: lineColors.green,
              borderWidth: 3,
              borderDash: [15, 20],
              borderDashOffset: 6,
              label: {
                position: "60%",
                yAdjust: 15,
                enabled: true,
                backgroundColor: lineColors.green,
                borderColor: lineColors.green,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () =>
                  "P1D Threshold: " + data[0]["P1D Threshold"] + " ft",
                rotation: "auto",
              },
            },
          }),
          ...(data[0]["P1D Action"] !== null && {
            P1Daction: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1D Action"],
              yMax: data[0]["P1D Action"],
              display: true,
              borderColor: lineColors.green,
              backgroundColor: lineColors.green,
              borderWidth: 4,
              borderCapStyle: "round",
              borderDash: [5, 10],
              borderDashOffset: 6,
              label: {
                position: "60%",
                yAdjust: -15,
                enabled: true,
                backgroundColor: lineColors.green,
                borderColor: lineColors.green,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () => "P1D Action: " + data[0]["P1D Action"] + " ft",
                rotation: "auto",
              },
            },
          }),

          ...(data[0]["P1E Threshold"] !== null && {
            P1Ethreshold: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1E Threshold"],
              yMax: data[0]["P1E Threshold"],
              display: true,
              borderColor: lineColors.purple,
              borderWidth: 3,
              borderDash: [15, 20],
              borderDashOffset: 9,
              label: {
                position: "85%",
                yAdjust: 15,
                enabled: true,
                backgroundColor: lineColors.purple,
                borderColor: lineColors.purple,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () =>
                  "P1E Threshold: " + data[0]["P1E Threshold"] + " ft",
                rotation: "auto",
              },
            },
          }),
          ...(data[0]["P1E Action"] !== null && {
            P1Eaction: {
              type: "line",
              yScaleID: "yL",
              yMin: data[0]["P1E Action"],
              yMax: data[0]["P1E Action"],
              display: true,
              borderColor: lineColors.purple,
              backgroundColor: lineColors.purple,
              borderWidth: 4,
              borderCapStyle: "round",
              borderDash: [5, 10],
              borderDashOffset: 9,
              label: {
                position: "85%",
                yAdjust: -15,
                enabled: true,
                backgroundColor: lineColors.purple,
                borderColor: lineColors.purple,
                borderRadius: 10,
                borderWidth: 2,
                color: "white",
                content: () => "P1E Action: " + data[0]["P1E Action"] + " ft",
                rotation: "auto",
              },
            },
          }),
        },
      };
      setAnnotatedLines(annotations);
      setFilteredMutatedGraphData(graphData);
    }
  }, [data]);

  const [enabledAnnotations, setEnabledAnnotations] = useState({
    threshold: true,
    action: true,
  });

  const [enabledLines, setEnabledLines] = useState({
    P1B: true,
    P1C: true,
    P1D: true,
    P1E: true,
  });

  const handleToggleAnnotation = (lineLabel) => {
    if (!["threshold", "action"].includes(lineLabel)) {
      setEnabledLines((ps) => {
        let ns = { ...ps };
        ns[lineLabel] = !ns[lineLabel];
        return ns;
      });
      setAnnotatedLines((prevState) => {
        let newState = { ...prevState };
        if (enabledAnnotations.threshold) {
          Object.entries(annotatedLines.annotations).forEach(
            (annotatedLine) => {
              if (
                annotatedLine[0].includes(lineLabel) &&
                annotatedLine[0].includes("threshold")
              ) {
                newState.annotations[annotatedLine[0]].display =
                  !newState.annotations[annotatedLine[0]].display;
              }
            }
          );
        }
        if (enabledAnnotations.action) {
          Object.entries(annotatedLines.annotations).forEach(
            (annotatedLine) => {
              if (
                annotatedLine[0].includes(lineLabel) &&
                annotatedLine[0].includes("action")
              ) {
                newState.annotations[annotatedLine[0]].display =
                  !newState.annotations[annotatedLine[0]].display;
              }
            }
          );
        }
        return newState;
      });
    }

    if (["threshold", "action"].includes(lineLabel)) {
      setAnnotatedLines((prevState) => {
        let newState = { ...prevState };
        if (enabledAnnotations[lineLabel]) {
          if (lineLabel === "threshold") {
            if (newState?.annotations?.P1Bthreshold?.display !== undefined) {
              newState.annotations.P1Bthreshold.display = false;
            }
            if (newState?.annotations?.P1Cthreshold?.display !== undefined) {
              newState.annotations.P1Cthreshold.display = false;
            }
            if (newState?.annotations?.P1Dthreshold?.display !== undefined) {
              newState.annotations.P1Dthreshold.display = false;
            }
            if (newState?.annotations?.P1Ethreshold?.display !== undefined) {
              newState.annotations.P1Ethreshold.display = false;
            }
          } else if (lineLabel === "action") {
            if (newState?.annotations?.P1Baction?.display !== undefined) {
              newState.annotations.P1Baction.display = false;
            }
            if (newState?.annotations?.P1Caction?.display !== undefined) {
              newState.annotations.P1Caction.display = false;
            }
            if (newState?.annotations?.P1Daction?.display !== undefined) {
              newState.annotations.P1Daction.display = false;
            }
            if (newState?.annotations?.P1Eaction?.display !== undefined) {
              newState.annotations.P1Eaction.display = false;
            }
          }
        } else {
          if (lineLabel === "threshold") {
            if (
              newState?.annotations?.P1Bthreshold?.display !== undefined &&
              enabledLines.P1B
            ) {
              newState.annotations.P1Bthreshold.display = true;
            }
            if (
              newState?.annotations?.P1Cthreshold?.display !== undefined &&
              enabledLines.P1C
            ) {
              newState.annotations.P1Cthreshold.display = true;
            }
            if (
              newState?.annotations?.P1Dthreshold?.display !== undefined &&
              enabledLines.P1D
            ) {
              newState.annotations.P1Dthreshold.display = true;
            }
            if (
              newState?.annotations?.P1Ethreshold?.display !== undefined &&
              enabledLines.P1E
            ) {
              newState.annotations.P1Ethreshold.display = true;
            }
          } else if (lineLabel === "action") {
            if (
              newState?.annotations?.P1Baction?.display !== undefined &&
              enabledLines.P1B
            ) {
              newState.annotations.P1Baction.display = true;
            }
            if (
              newState?.annotations?.P1Caction?.display !== undefined &&
              enabledLines.P1C
            ) {
              newState.annotations.P1Caction.display = true;
            }
            if (
              newState?.annotations?.P1Daction?.display !== undefined &&
              enabledLines.P1D
            ) {
              newState.annotations.P1Daction.display = true;
            }
            if (
              newState?.annotations?.P1Eaction?.display !== undefined &&
              enabledLines.P1E
            ) {
              newState.annotations.P1Eaction.display = true;
            }
          }
        }
        return newState;
      });

      setEnabledAnnotations((ps) => {
        let ns = { ...ps };
        ns[lineLabel] = !ns[lineLabel];
        return ns;
      });
    }
  };

  const active = (bool) => {
    if (bool === null) return;
    return bool
      ? {
          backgroundColor: "#105973",
          transition: "transform .5s ease-in-out, box-shadow .5s ease-in-out",
          transform: "scale(.95)",
          boxShadow: "2px 1px 2px rgba(0,0,0,0.15)",
        }
      : {
          transition: "transform .5s ease-in-out, box-shadow .5s ease-in-out",
          transform: "scale(1)",
          boxShadow: "2px 5px 15px rgba(0,0,0,0.3)",
        };
  };

  return (
    <>
      <Helmet title="Stagecoach Study Section 1" />
      <Typography variant="h3" gutterBottom display="inline">
        Stagecoach Reservoir - Study Section 1 Piezometer Data
      </Typography>

      {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
      {/*  <Link component={NavLink} exact to="/dashboard">*/}
      {/*    Dashboard*/}
      {/*  </Link>*/}
      {/*  <Typography>Time Series Comparisons</Typography>*/}
      {/*</Breadcrumbs>*/}

      <Divider my={6} />

      <Grid container spacing={6}>
        {Endpoints && (
          <>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Options
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <Grid container pb={6} mt={2}>
                      <Grid item xs={12}>
                        <Select
                          name="endpoint"
                          label="Graph Options"
                          variant="outlined"
                          valueField="value"
                          displayField="label"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={Endpoints}
                          value={filterValues.endpoint}
                          onChange={handleFilter}
                          width="calc(100% - 162px - 162px)"
                        />
                        <DatePicker
                          name="startDate"
                          label="Start Date"
                          variant="outlined"
                          outlineColor="primary"
                          labelColor="primary"
                          value={filterValues.startDate}
                          onChange={handleFilter}
                          width={150}
                        />
                        <DatePicker
                          name="endDate"
                          label="End Date"
                          variant="outlined"
                          outlineColor="primary"
                          labelColor="primary"
                          value={filterValues.endDate}
                          onChange={handleFilter}
                          width={150}
                        />
                      </Grid>

                      <SubmitGrid item container>
                        <Grid>
                          <Button
                            onClick={() => refetch()}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            size="large"
                            disabled={
                              !filterValues.startDate || !filterValues.endDate
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: "auto" }}>
                          <Tooltip title="Quick set dates">
                            <Button
                              onClick={() => {
                                setFilterValues((prevState) => {
                                  let newValues = { ...prevState };

                                  newValues.startDate = extractDate(
                                    add(new Date(), { days: -60 })
                                  );
                                  newValues.endDate = extractDate(new Date());

                                  return newValues;
                                });
                              }}
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="small"
                            >
                              Last 60 Days
                            </Button>
                          </Tooltip>
                          <Tooltip title="Quick set dates">
                            <Button
                              ml={3}
                              onClick={() => {
                                setFilterValues((prevState) => {
                                  let newValues = { ...prevState };

                                  newValues.startDate = extractDate(
                                    add(new Date(), { days: -7 })
                                  );
                                  newValues.endDate = extractDate(new Date());

                                  return newValues;
                                });
                              }}
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="small"
                            >
                              Last 7 Days
                            </Button>
                          </Tooltip>
                        </Grid>
                      </SubmitGrid>
                    </Grid>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      <>
        {data && Object.entries(annotatedLines).length > 0 && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Graph
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <TimeseriesContainer>
                      <Box
                        pb={1}
                        display="flex"
                        alignItems="center"
                        style={{ width: "100%" }}
                      >
                        <Button
                          size="small"
                          style={{
                            width: "200px",
                            marginRight: "20px",
                            ...active(enabledAnnotations.threshold ?? null),
                          }}
                          color="primary"
                          variant="contained"
                          onClick={() => handleToggleAnnotation("threshold")}
                        >
                          Threshold Level
                        </Button>

                        <Button
                          size="small"
                          style={{
                            width: "200px",
                            marginRight: "20px",
                            ...active(enabledAnnotations.action ?? null),
                          }}
                          color="primary"
                          variant="contained"
                          onClick={() => handleToggleAnnotation("action")}
                        >
                          Action Level
                        </Button>
                        <span
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          <SaveGraphButton
                            ref={saveRef}
                            title={`${data[0]?.view_name} Graph`}
                            disabled={isFetching || !data}
                          />
                        </span>
                      </Box>

                      <TableWrapper>
                        <TimeseriesLineChartResops
                          handleToggleAnnotation={handleToggleAnnotation}
                          annotatedLines={annotatedLines}
                          data={filteredMutatedGraphData}
                          reverseLegend={false}
                          title={data[0]?.view_name}
                          error={error}
                          isLoading={isFetching}
                          filterValues={filterValues}
                          yLLabel="Reservoir Elevation, Ft ab MSL"
                          xLabelFormat="MMM DD YYYY"
                          ref={saveRef}
                        />
                      </TableWrapper>
                    </TimeseriesContainer>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        )}

        {data && !isFetching && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="table-content"
                  id="table-header"
                >
                  <Typography variant="h4" ml={2}>
                    Table
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <TableWrapper>
                      <Table
                        // isLoading={isLoading}
                        label="Daily Groundwater Elevation Timeseries Table"
                        columns={tableColumns}
                        data={data}
                        height="590px"
                      />
                    </TableWrapper>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
};

export default ResopsView01TimeSeries;
